.v--modal-overlay .v--modal-background-click {
  background-color: rgba(0, 0, 0, 0.8);
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.modal-text {
  h2 {
    @apply font-semibold pb-3 text-3xl text-black;
  }
}

#recipeModal {
  visibility: hidden;
  max-width: 1px;
  height: 1px;
  display: none;
  box-sizing: border-box;
  width: 99%;
  padding: 1rem;
}

@media print {
  body * {
    visibility: hidden;
  }
  html,
  body {
    //border: 1px solid white;
    page-break-after: avoid;
    page-break-before: avoid;
    #dashboard, footer {
      display: none;
    }
    > .container {
      padding-bottom: 0;
    }
  }
  #modalInfo * {
    display: none;
    visibility: hidden;
    height: 0 !important;
  }
  body * {
    &:not(#recipeModal) {
      height: 0px !important;
    }
  }
  body #recipeModal {
    display: block;
    max-width: 100%;
    height: auto;
    max-height: auto;
    overflow: hidden;
    page-break-after: always;
    br {
      display: none;
    }
  }
  body #recipeModal * {
    position: relative;
    top: 0;
    left: 0;
    visibility: visible;
    height: auto !important;
  }
  p {
    page-break-before: avoid;
  }
  br {
    display: none;
  }
}
body .v--modal-overlay.scrollable .v--modal-background-click .v--modal-box.v--modal {
  top: 0 !important;
}

.recipe-preparation {
  li {
    strong {
      padding-left: 1rem;
    }
  }
}

.leave-modal .v--modal-box, .leave-modal {
  top: 0 !important;
}
